* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html,
#root {
  position: relative;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

/*
  Notification
 */
.notification-container-top-right {
  margin-top: 62px;
  margin-right: 20px;
  width: auto;
  .n-parent {
    min-width: 350px !important;
  }
}
.notification-item .notification-content {
  padding: 15px !important;
  max-width: 650px;
  white-space: pre-line;
  max-height: 300px;
  overflow-y: auto;
  cursor: default;
}

.notification-content::-webkit-scrollbar-track {
  background: transparent !important;
}
.notification-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px;
}
.notification-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.notification-content::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.notification-item .notification-close::after {
  font-size: 20px !important;
}

.notification-item .notification-close {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  transform: translate(5px, -5px);
  background-color: transparent;
}

.notification-message .copy-message {
  position: absolute;
  top: 9px;
  right: 30px;
}

.show-on-hover {
  opacity: 0 !important;
}
.MuiInputBase-root:hover .show-on-hover {
  opacity: 1 !important;
}

[class^='MuiInputBase-root']:hover .show-on-hover {
  opacity: 1 !important;
}
/* rc-tree-select */

.rc-tree-select {
  min-width: 250px;
}

.rc-tree-select-tree-node-content-wrapper {
  display: flex;
  width: calc(100% - 40px);
  padding: 5px 10px !important;
  cursor: pointer;
}

.rc-tree-select-tree-node-content-wrapper:hover {
  background-color: rgba(33, 133, 208, 0.2);
}

.rc-tree-select-tree-node-selected {
  width: calc(100% - 40px);
  display: block;
  background-color: #2185d0;
  color: white;
  border: none;
}
.rc-tree-select-tree-node-selected:hover {
  background-color: #2185d0;
}
.rc-tree-select-tree li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.rc-tree-select-tree:focus {
  outline: none;
}

.rc-tree-select-tree-switcher {
  width: 34px !important;
  height: 34px !important;
  background: white none !important;
  border-radius: 50%;
}

.rc-tree-select-tree-switcher_close:hover,
.rc-tree-select-tree-switcher_open:hover {
  background: rgba(33, 133, 208, 0.2) none !important;
  cursor: pointer !important;
}

.kato-switch-icon {
  width: 34px;
  height: 34px;
}

.rc-tree-select-tree-switcher_close .kato-switch-icon {
  background: url('../static/arrow-right.svg') no-repeat center center;
  height: 20px;
  width: 20px;
  margin: 7px;
}

.rc-tree-select-tree-switcher_open .kato-switch-icon {
  background: url('../static/arrow-down.svg') no-repeat center center;
  height: 20px;
  width: 20px;
  margin: 7px;
}

.rc-tree-select-tree-title {
  display: block;
}

.rc-tree-select-dropdown {
  z-index: 2000;
  font-size: 15px;
}

.rc-tree-select-dropdown > div:focus {
  outline: none;
}
.rc-tree-select-tree li ul {
  width: 100%;
}
.rc-tree-select-tree li span.rc-tree-select-tree-icon_loading {
  margin-right: 8px;
  margin-top: 3px;
}

.rc-tree-select-tree li ul {
  padding: 0 0 0 30px;
}

.rc-tree-select-selection--multiple > ul > li {
  margin-top: 4px;
  line-height: 26px;
  height: 26px;
  color: #222222;
}
.rc-tree-select .rc-tree-select-selection__choice {
  background-color: #e6e6e6;
  padding-right: 20px;
}

.rc-tree-select-enabled
.rc-tree-select-selection__choice
.rc-tree-select-selection__choice__remove {
  opacity: 1;
  transform: scale(1);
}

.rc-tree-select-enabled
.rc-tree-select-selection__choice
.rc-tree-select-selection__choice__remove
+ .rc-tree-select-selection__choice__content {
  margin-right: 8px;
  margin-left: -8px;
}

.rc-tree-select-selection__choice__remove {
  padding-right: 8px !important;
  padding-left: 5px !important;
  margin-left: 3px !important;
  margin-right: -3px !important;
}

.rc-tree-select-selection__choice__remove:hover {
  background-color: #ffbdad !important;
  color: red !important;
}

.rc-tree-select-selection__choice {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rc-tree-select-search__field__placeholder {
  color: #d2d2d2;
}

.rc-tree-select-selection--multiple .rc-tree-select-search__field__placeholder {
  top: 8px;
  left: 15px;
}

/* rc-tree-select end */

.rc-tree-select-selection {
  border-color: rgba(0, 0, 0, 0.1);
}

.rc-tree-select-allow-clear .rc-tree-select-selection--single .rc-tree-select-selection__rendered {
  padding-right: 68px;
}

.rc-tree-select-selection-selected-value {
  line-height: 28px;
}
.rc-tree-select-selection--single .rc-tree-select-selection__clear {
  top: -2px;
}

/*
  Scroll
*/

.fullWidth {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.select-none {
  user-select: none !important;
  pointer-events: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input[placeholder] {
  text-overflow: ellipsis;
}
input::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}
input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.rt-table div {
  min-width: initial !important;
}

.rt-td,
.rt-th {
  width: 0 !important;
}

/*[class*="legendNotched"] {
  max-width: 0.01px !important;
}*/

/**
	Подсказки для Js style
 */
.text-primary,
.text-secondary,
.color-primary,
.color-secondary,
.text-success,
.bg-color,
.bg-primary,
.bg-secondary,
.border-primary,
.border-secondary,
.border,
.border-top,
.border-radius,
.card {
}

/*
  antd
*/
.ant-input {
  border: 1px solid rgba(34, 36, 38, 0.3);
  color: rgba(0, 0, 0, 0.9) !important;
}
.changed-style .ant-input {
  color: #43a047 !important;
  font-weight: bold;
}
.ant-input::placeholder {
  color: #d2d2d2;
}
.ant-input:focus {
  border: 1px solid #85b7d9;
}
.ant-input:hover {
  border: 1px solid rgba(34, 36, 38, 0.25);
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border: 1px solid rgba(34, 36, 38, 0.25);
}

.rselect-nested-option:hover {
  background-color: rgba(33, 133, 208, 0.2);
}

.ant-calendar-picker-container,
.ant-calendar-date {
  color: rgb(0, 0, 0);
}

.ant-input-lg {
  height: 38px;
  font-size: 14px;
  padding: 6px 15px;
}

.modal {
  overflow: inherit;
  height: auto !important;
}

.react-select-error {
  > div {
    background-color: #fff6f6;
    border: 1px solid #e0b4b4;
    border-radius: 5px;
    color: #9f3a38;
  }
}
.has-error > div {
  border: 1px solid #e0b4b4;
  border-radius: 4px;
  background-color: #fff6f6 !important;
  input {
    background-color: transparent;
  }
  input.ant-input {
    border: none;
    &:hover {
      border: none !important;
    }
  }
}
.has-error-text {
  color: #cc5c5c;
  font-size: 13px;
}
.irrelevant label {
  color: #cc5c5c !important;
}

div.rt-tr {
  align-items: center; // эту строчку не удаляйте
  //height: 46px; это общий компонент
}

.flk-require {
  font-size: 21px;
  color: #ff4036;
  margin-left: 10px;
}

#componentToPrint {
  display: none;
  margin: 20px 20px 20px 40px;
}

#componentToPrint table td,
#componentToPrint table th {
  padding: 5px;
  font-size: 12px;
}

@media print {
  #componentToPrint {
    display: block;
  }
  @page {
    size: landscape;
  }
}

.ReactVirtualized__Table__headerRow {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}

.Table {
  width: 100%;
  margin-top: 15px;
}
.headerRow,
.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}
.oddRow {
  background-color: #fafafa;
}
.headerColumn {
  text-transform: none;
}
.exampleColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxLabel {
  margin-left: 0.5rem;
}
.checkboxLabel:first-of-type {
  margin-left: 0;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.ct-datepicker input:hover {
  border: none !important;
}

.vseobuch-table {
  width: 200%;
}

.vseobuch-table .ReactTable .rt-tbody {
  overflow: visible !important;
}

.token-test {
  position: absolute;
  right: 10px;
  bottom: -40px;
  z-index: 3;
  font-size: 13px;
  button {
    cursor: pointer;
  }
}

.ant-input:focus {
  box-shadow: none !important;
}

.notification-message .message {
  white-space: pre-line;
}

.fullwidth {
  width: 100%;
}
.kindergartenServices {
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.kindergartenServices.dqcjji {
  max-width: 100% !important;
}
.kindergartenServices.mb3 .mt3 {
  width: 100% !important;
}